@import "mediaquery";
@import "typography";

$image_path: '../images/';

$main-black: #111212;
$main-red: #BA1A35;
$light-gray: #EDEDED;
$gray: #CCCCCC;
$dark-gray: #999999;

$brown: #936B44;
$light-beige: #C69C6D;
$white-beige: #F4E3D3;

$blue-black: #202834;
$blue: #3E56A3;
$light-blue: #7B8AAD;