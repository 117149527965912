@import "./../config/mediaquery";
@import "./../config/typography";
@import "./../config/variables";

.index {
  &-gallery {
    position: relative;
    @media #{$minM} {
      margin-bottom: 100px;
    }
    @media #{$maxM} {
      margin-bottom: 80px;
    }
    .text {
      color: #fff;
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      .en {
        @include en-font-family;
        font-weight: 700;
        letter-spacing: 0.08em;
        line-height: 1;
        @media #{$minM} {
          font-size: 80px;
          margin-bottom: 20px;
        }
        @media #{$maxM} {
          font-size: 28px;
          margin-bottom: 5px;
        }
      }
      .jp {
        font-weight: bold;
        letter-spacing: 0.15em;
        @media #{$minM} {
          font-size: 30px;
        } 
        @media #{$maxM} {
          font-size: 14px;
        }
      }
    }
    .index-slider {
      font-size: 0;
    }
    .news {
      position: absolute;
      @media #{$minM} {
        right: 50px;
        bottom: 50px;
      }
      @media #{$maxM} {
        left: 0;
        bottom: -35px;
        width: 100%;
      }
      .link {
        display: flex;
        @media #{$minM} {
          background-color: #fff;
          border-radius: 4px;
          font-size: 13px;
          line-height: 60px;
        }
        @media #{$maxM} {
          background-color: $light-gray;
          // justify-content: space-between;
          font-size: 11px;
          line-height: 35px;
          width: 100%;
        }
        .date {
          font-weight: 700;
          @media #{$minM} {
            padding-left: 20px;
            padding-right: 20px;
          }
          @media #{$maxM} {
            padding-left: 10px;
            padding-right: 15px;
          }
        }
        .title {
          font-weight: 700;
          @media #{$minM} {
            overflow: hidden;
            padding-right: 27px;
            height: 60px;
            max-width: 220px;
          }
          @media #{$maxM} {
            padding-right: 15px;
            max-width: 180px;
            height: 30px;
          }
        }
        .view {
          @include en-font-family;
          background-color: $gray;
          color: $main-red;
          @media #{$minM} {
            border-radius: 0 4px 4px 0;
            padding-left: 27px;
            padding-right: 27px;
          }
          @media #{$maxM} {
            padding-left: 10px;
            padding-right: 10px;
            position: absolute;
            top: 0;
            right: 0;
            text-align: center;
          }
        }
      }
    }
  }
  &-section {
    @media #{$minM} {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 140px;
      max-width: 1160px;
      padding-left: 40px;
      padding-right: 40px;
    }
    @media #{$maxM} {
      margin-bottom: 55px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  &-item {
    &:not(:last-child) {
      @media #{$minM} {
        margin-bottom: 40px;
      }
      @media #{$maxM} {
        margin-bottom: 20px;
      }
    }
  }
  &-link {
    display: block;
    overflow: hidden;
    position: relative;
    @media #{$minM} {
      border-radius: 4px;
      height: 320px;
    }
    @media #{$maxM} {
      border-radius: 4px;
      height: 200px;
    }
    .title {
      color: #fff;
      text-align: center;
      position: absolute;
      top: 53%;
      left: 50%;
      transform: translate(-50%, -50%);
      .en {
        @include en-font-family;
        line-height: 0.8;
        @media #{$minM} {
          font-size: 42px;
        }
        @media #{$maxM} {
          font-size: 26px;
        }
      }
      .jp {
        @media #{$minM} {
          font-size: 15px;
          margin-bottom: 15px;
        }
        @media #{$maxM} {
          margin-bottom: 10px;
        }
      }
      .jp2 {
        .en {
          @include en-font-family;
          font-weight: bold;
          @media #{$minM} {
            font-size: 42px;
          }
          @media #{$maxM} {
            font-size: 26px;
          }
        }
        .jp {
          font-weight: bold;
          @media #{$minM} {
            font-size: 32px;
          }
          @media #{$maxM} {
            font-size: 18px;
          }
        }
      }
      .button {
        @include en-font-family;
        @media #{$minM} {
          font-size: 16px;
        }
      }
    }
    img {
      height: 100%;
      width: auto;
      object-fit: cover;
    }
  }
  &-flex {
    @media #{$minM} {
      display: flex;
      justify-content: space-between;
    }
  }
  &-box {
    @media #{$minM} {
      width: calc((100% - 40px) / 2);
    }
    &:not(:last-child) {
      @media #{$maxM} {
        margin-bottom: 20px;
      } 
    }
    img {
      @media #{$maxM} {
        width: 100%;
        height: auto;
      }
    }
  }
}