@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&family=Roboto:wght@700&display=swap');

@mixin base-font-family {
  font-family: 'Noto Sans JP', "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", "YuGothic", YuGothic, "Hiragino Kaku Gothic ProN", "Hiragino Sans", sans-serif;
}

@mixin en-font-family {
  font-family: 'Roboto', sans-serif;
}













